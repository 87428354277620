/**
 * Product Option Client Helper
 */
import { kebabCase } from '@fergdigitalcommerce/fergy-utilities';
import { logError } from 'fergy-core-react-logging';
import { type RequiredNonNullable } from '../../../../../@types/build';
import {
	type AddToCartInput,
	type GroupSelectionSourceTypeEnum,
	type OptionSelectionResourceFieldsFragment,
	type RecommendedOptionSelectionFieldsFragment,
	type RequiredGroupSelectionFieldsFragment,
	type RequiredOptionSelectionFieldsFragment
} from '../../../__generated__/graphql-client-types';
import { type ProductResourceVariantCard } from '../../../types/product.types';
import { AnalyticsHelper } from '../../analytics/analytics.helper';
import { buildGTMSelectedVariation } from '../../analytics/gtm/event-builders';
import {
	type CombinedOptionGroup,
	type OptionSelection,
	isOptionSelected,
	isRequiredOptionSelection,
	isVariationGroupSelection
} from './product-configuration.helper';

const toggle = (option: RequiredOptionSelectionFieldsFragment): boolean => {
	if (option.selectionType === 'SINGLE') {
		// For single selection: if it's selected, it must remain selected (it forces returning always false only for this case).
		return !option.selected ? option.selected : false;
	} else if (option.selectionType === 'INDEPENDENT') {
		return true;
	}
	// Otherwise, return the current selected state subjected for toggling (Multiple Selection - selected/deselected).
	return option.selected;
};

export type AddToCartAction = (
	input: AddToCartInput,
	option: RequiredOptionSelectionFieldsFragment | RecommendedOptionSelectionFieldsFragment,
	selectedVariant: ProductResourceVariantCard,
	source?: GroupSelectionSourceTypeEnum
) => Promise<void>;

export const areSelectionsAvailable = (selections?: OptionSelection[]): boolean =>
	typeof selections !== 'undefined' && selections.length > 0;

export const openProductLinkInNewTab = (link: string) => window.open(link, '_blank');

export const isCurrentOptionSelected = (option: OptionSelection, shouldToggle = false): boolean => {
	if (shouldToggle && isRequiredOptionSelection(option)) {
		return toggle(option);
	} else {
		return option.selected;
	}
};

export const isKeycodeField = (name?: string) => name && name.indexOf('Key Code') !== -1;

export const handleProductOptionClick = (
	option: OptionSelection,
	group: CombinedOptionGroup,
	navigate: (url: string, replace?: boolean) => void,
	change: (currentOption: OptionSelection, updatedGroupSelection?: Partial<RequiredGroupSelectionFieldsFragment>) => void
): void => {
	if (isVariationGroupSelection(group) && group.type === 'VARIATION' && option?.resource?.url) {
		const uniqueId = Number(option.resource.url.split('=')[1]) || 0;
		AnalyticsHelper.track(buildGTMSelectedVariation(option.name, uniqueId)).catch(logError);
		return navigate(option.resource.url);
	}
	return change(option, { valid: true });
};

export const changeGroupValidity = (
	groupSelection: RequiredGroupSelectionFieldsFragment,
	option: RequiredOptionSelectionFieldsFragment,
	optionSelections?: RequiredOptionSelectionFieldsFragment[]
): RequiredGroupSelectionFieldsFragment => {
	let valid = typeof groupSelection.valid !== 'undefined' ? groupSelection.valid : true;
	if (optionSelections) {
		if (option.type === 'TEXTBOX' && valid) {
			valid = optionSelections.some((optionSelection) => isOptionSelected(optionSelection));
		} else if (option.type === 'CHECKBOX' && !option.selected) {
			const allOptionsExceptCurrent = optionSelections.filter((optionSelection) => optionSelection.id !== kebabCase(option.name));
			valid = allOptionsExceptCurrent.some((optionSelection) => isOptionSelected(optionSelection));
		}
	}
	return { ...groupSelection, valid };
};

/**
 * Generates selector for a product option name.
 * Replaces double quotes with the word 'inch' and removes extraneous info in parens.
 *
 * @param {string} id - automation id
 */
export const generateProductOptionNameSelector = (id: string) => {
	return id
		.replace(/\s?\(.+?\)/, '')
		.split(' ')
		.join('-')
		.toLowerCase()
		.replace('"', '-inch');
};

/**
 * Retrieve resources of all the options passed by parameter
 * This function will filter out those resources considered invalid
 * {@see resolveResource()}
 */
export const getProductResourcesByOptions = (
	options: RequiredOptionSelectionFieldsFragment[]
): RequiredNonNullable<OptionSelectionResourceFieldsFragment>[] => {
	return options
		.map(({ resource }) => (resource ? resolveProductResource(resource) : null))
		.filter((item): item is RequiredNonNullable<OptionSelectionResourceFieldsFragment> => Boolean(item));
};

/**
 * Validates an option resource if the given resource contains all product information.
 * Otherwise, null is returned.
 */
export const resolveProductResource = (
	resource: OptionSelectionResourceFieldsFragment
): RequiredNonNullable<OptionSelectionResourceFieldsFragment> | null => {
	const { variantId, brandName, modelNumber, url, status, price, finishes } = resource;
	if (variantId && brandName && modelNumber && url && price && status && finishes.length > 0) {
		const __typename = resource.__typename || 'OptionSelectionResource';
		const quantity = resource.quantity || 1;
		const rating = resource.rating || { ratingValue: 0, reviewCount: 0 };
		return { ...resource, __typename, variantId, brandName, modelNumber, url, price, finishes, status, quantity, rating };
	}
	return null;
};

export const updateOptionResource = (
	resource: OptionSelectionResourceFieldsFragment,
	variant?: ProductResourceVariantCard,
	newQuantity = 1
): OptionSelectionResourceFieldsFragment => {
	return variant
		? {
				...resource,
				title: variant.title === 'N/A' ? resource.title : variant.title,
				status: variant.status,
				url: variant.url,
				modelNumber: variant.modelNumber,
				price: {
					...(resource.price ? resource.price : { discount: null, packaging: null, unitPrice: null }),
					current: variant.price
				},
				quantity: newQuantity
		  }
		: resource;
};
